import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('typePage', "");

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Edit Config
export function Reg_EditConfig(idiomaEn, idiomaEs, idiomaPtBr, listColor, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'dataConfig');
    data.append('edit_data', 'edit_or_register_data');

    data.append('idiomaEn', idiomaEn);
    data.append('idiomaEs', idiomaEs);
    data.append('idiomaPtBr', idiomaPtBr);

    listColor.forEach((elem, index) => {
        data.append('config_id[]', elem.id);
        data.append('config_en[]', elem.en);
        data.append('config_es[]', elem.es);
        data.append('config_pt_br[]', elem.pt_br);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('dataConfig', response.data.dataConfig);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('accessDash', response.data.accessDash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.accessDash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('accessDash', response.data.accessDash);
        const newData = response.data.accessDash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Change position
export function Reg_AltPosition(current_page, id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_data', 'alt_position');
    data.append('type_page', current_page);
    data.append('typePage', "");

    data.append('id', id);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data, current_page);
        SetListDataSingle(current_page, response.data[current_page]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Register data
export function Reg_RegisterData(userId, idPage, title, color, typePage, ptBrContent, enContent, esContent, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();
    data.append('edit_data', 'edit_or_register_data');
    data.append('type_page', 'site');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('color', color);
    data.append('typePage', typePage);

    switch (typePage) {
        case "home":
                // en
                if(enContent.en_contents){
                    enContent.en_contents.forEach((elem, index)=> {
                        data.append('en_id[]', elem.id);
                        data.append('en_type_file[]', elem.typeFile);
                        data.append('en_video[]', elem.video);
                        data.append('en_file_' + index, elem.file);
                        data.append('en_title[]', elem.title);
                        data.append('en_text[]', elem.text);
                        data.append('en_name_btn[]', elem.name_btn);
                        data.append('en_link_btn[]', elem.link_btn);
                    });
                }
                // es
                if(esContent.es_contents){
                    esContent.es_contents.forEach((elem, index)=> {
                        data.append('es_id[]', elem.id);
                        data.append('es_type_file[]', elem.typeFile);
                        data.append('es_video[]', elem.video);
                        data.append('es_file_' + index, elem.file);
                        data.append('es_title[]', elem.title);
                        data.append('es_text[]', elem.text);
                        data.append('es_name_btn[]', elem.name_btn);
                        data.append('es_link_btn[]', elem.link_btn);
                    });
                }
                // pt_br
                if(ptBrContent.pt_br_contents){
                    ptBrContent.pt_br_contents.forEach((elem, index)=> {
                        data.append('pt_br_id[]', elem.id);
                        data.append('pt_br_type_file[]', elem.typeFile);
                        data.append('pt_br_video[]', elem.video);
                        data.append('pt_br_file_' + index, elem.file);
                        data.append('pt_br_title[]', elem.title);
                        data.append('pt_br_text[]', elem.text);
                        data.append('pt_br_name_btn[]', elem.name_btn);
                        data.append('pt_br_link_btn[]', elem.link_btn);
                    });
                }
            break;

        case "gallery": case "products":
                // en
                if(enContent){                    
                    data.append('en_title', enContent.en_title);
                    data.append('en_text', enContent.en_text);
                        
                    if(enContent.en_contents){
                        enContent.en_contents.forEach((elem_1, index_1) => {
                            data.append('en_contents_id[]', elem_1.id);
                            data.append('en_contents_file_' + index_1, elem_1.file);
                            data.append('en_contents_title[]', elem_1.title);
                            data.append('en_contents_text[]', elem_1.text);
                        });
                    }
                }
                // es
                if(esContent){
                    data.append('es_title', esContent.es_title);
                    data.append('es_text', esContent.es_text);
                        
                    if(esContent.es_contents){
                        esContent.es_contents.forEach((elem_1, index_1) => {
                            data.append('es_contents_id[]', elem_1.id);
                            data.append('es_contents_file_' + index_1, elem_1.file);
                            data.append('es_contents_title[]', elem_1.title);
                            data.append('es_contents_text[]', elem_1.text);
                        });
                    }
                }
                // pt_br
                if(ptBrContent){
                    data.append('pt_br_title', ptBrContent.pt_br_title);
                    data.append('pt_br_text', ptBrContent.pt_br_text);
                        
                    if(ptBrContent.pt_br_contents){
                        ptBrContent.pt_br_contents.forEach((elem_1, index_1) => {
                            data.append('pt_br_contents_id[]', elem_1.id);
                            data.append('pt_br_contents_file_' + index_1, elem_1.file);
                            data.append('pt_br_contents_title[]', elem_1.title);
                            data.append('pt_br_contents_text[]', elem_1.text);
                        });
                    }
                }
            break;

        case "testimonials":
                // en
                if(enContent){
                    data.append('en_title', enContent.en_title);
                    data.append('en_text', enContent.en_text);
                        
                    if(enContent.en_contents){
                        enContent.en_contents.forEach((elem_1, index_1) => {
                            data.append('en_contents_index_1[]', index_1);
                            data.append('en_contents_id[]', elem_1.id);
                            data.append('en_contents_file_' + index_1, elem_1.file);
                            data.append('en_contents_user_' + index_1, elem_1.user);
                            data.append('en_contents_name[]', elem_1.title);
                            data.append('en_contents_office[]', elem_1.office);
                            data.append('en_contents_text[]', elem_1.text);
                        });
                    }
                }
                // es
                if(esContent){
                    data.append('es_title', esContent.es_title);
                    data.append('es_text', esContent.es_text);
                        
                    if(esContent.es_contents){
                        esContent.es_contents.forEach((elem_1, index_1) => {
                            data.append('es_contents_index_1[]', index_1);
                            data.append('es_contents_id[]', elem_1.id);
                            data.append('es_contents_file_' + index_1, elem_1.file);
                            data.append('es_contents_user_' + index_1, elem_1.user);
                            data.append('es_contents_name[]', elem_1.title);
                            data.append('es_contents_office[]', elem_1.office);
                            data.append('es_contents_text[]', elem_1.text);
                        });
                    }
                }
                // pt_br
                if(ptBrContent){
                    data.append('pt_br_title', ptBrContent.pt_br_title);
                    data.append('pt_br_text', ptBrContent.pt_br_text);
                        
                    if(ptBrContent.pt_br_contents){
                        ptBrContent.pt_br_contents.forEach((elem_1, index_1) => {
                            data.append('pt_br_contents_index_1[]', index_1);
                            data.append('pt_br_contents_id[]', elem_1.id);
                            data.append('pt_br_contents_file_' + index_1, elem_1.file);
                            data.append('pt_br_contents_user_' + index_1, elem_1.user);
                            data.append('pt_br_contents_name[]', elem_1.title);
                            data.append('pt_br_contents_office[]', elem_1.office);
                            data.append('pt_br_contents_text[]', elem_1.text);
                        });
                    }
                }
            break;

        case "about":
                // en
                if(enContent){
                    data.append('en_subtitle', enContent.en_subtitle);
                    data.append('en_file', enContent.en_file);
                    data.append('en_title', enContent.en_title);
                    data.append('en_text', enContent.en_text);
                }
                // es
                if(esContent){
                    data.append('es_subtitle', esContent.es_subtitle);
                    data.append('es_file', esContent.es_file);
                    data.append('es_title', esContent.es_title);
                    data.append('es_text', esContent.es_text);
                }
                // pt_br
                if(ptBrContent){
                    data.append('pt_br_subtitle', ptBrContent.pt_br_subtitle);
                    data.append('pt_br_file', ptBrContent.pt_br_file);
                    data.append('pt_br_title', ptBrContent.pt_br_title);
                    data.append('pt_br_text', ptBrContent.pt_br_text);
                }
            break;

        case "contact":
                // en
                if(enContent){
                    data.append('en_title', enContent.en_title);
                    data.append('en_text', enContent.en_text);
                }
                // es
                if(esContent){
                    data.append('es_title', esContent.es_title);
                    data.append('es_text', esContent.es_text);
                }
                // pt_br
                if(ptBrContent){
                    data.append('pt_br_title', ptBrContent.pt_br_title);
                    data.append('pt_br_text', ptBrContent.pt_br_text);
                }
            break;

        case "footer":
                // en
                if(enContent){
                    data.append('en_name_btn', enContent.en_name_btn);
                    data.append('en_file', enContent.en_file);
                    data.append('en_link_btn', enContent.en_link_btn);
                    data.append('en_title', enContent.en_title);
                    data.append('en_name_btn_2', enContent.en_name_btn_2);
                    data.append('en_link_btn_2', enContent.en_link_btn_2);
                }
                // es
                if(esContent){
                    data.append('es_name_btn', esContent.es_name_btn);
                    data.append('es_file', esContent.es_file);
                    data.append('es_link_btn', esContent.es_link_btn);
                    data.append('es_title', esContent.es_title);
                    data.append('es_name_btn_2', esContent.es_name_btn_2);
                    data.append('es_link_btn_2', esContent.es_link_btn_2);
                }
                // pt_br
                if(ptBrContent){
                    data.append('pt_br_name_btn', ptBrContent.pt_br_name_btn);
                    data.append('pt_br_file', ptBrContent.pt_br_file);
                    data.append('pt_br_link_btn', ptBrContent.pt_br_link_btn);
                    data.append('pt_br_title', ptBrContent.pt_br_title);
                    data.append('pt_br_name_btn_2', ptBrContent.pt_br_name_btn_2);
                    data.append('pt_br_link_btn_2', ptBrContent.pt_br_link_btn_2);
                }
            break;
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('site', response.data.site);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}