import { useState, useEffect } from "react";

import './Contact.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Model_Contact(props){
    
    const [ dataPage, setDataPage ] = useState(GetDataPage("site"));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enTitle, setEnTitle ] = useState(InitialData('en_contents', 'en_title'));
    const [ enText, setEnText ]   = useState(InitialData('en_contents', 'en_text'));

    // es
    const [ esTitle, setEsTitle ] = useState(InitialData('es_contents', 'es_title'));
    const [ esText, setEsText ]   = useState(InitialData('es_contents', 'es_text'));

    // pt_br
    const [ ptBrTitle, setPtBrTitle ] = useState(InitialData('pt_br_contents', 'pt_br_title'));
    const [ ptBrText, setPtBrText ]   = useState(InitialData('pt_br_contents', 'pt_br_text'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('site', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnTitle(InitialData('en_contents', 'en_title'));
        setEnText(InitialData('en_contents', 'en_text'));

        // es
        setEsTitle(InitialData('es_contents', 'es_title'));
        setEsText(InitialData('es_contents', 'es_text'));  
        
        // pt_br
        setPtBrTitle(InitialData('pt_br_contents', 'pt_br_title'));
        setPtBrText(InitialData('pt_br_contents', 'pt_br_text'));      
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData('pt_br', { "pt_br_title": value, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText });
                break;
            case "ptBrText":
                    setPtBrText(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": value });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText });
                break;

            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_title": value, "en_text": enText });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText });
                break;
            case "enText":
                    setEnText(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": value });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText });
                break; 

            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_title": value, "es_text": esText });
                break;                
            case "esText":
                    setEsText(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', {"es_title": esTitle, "es_text": value });
                break;
        }
    }

    return(
        <div className="Model_Contact">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrTitle', e.target.value) } } value={ ptBrTitle } required />
                                <span className="name_input">Título</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrText !='' ? ptBrText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrText', newContent) } />
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ?
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } />
                                <div className="name_input">Title</div>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <div className="show_title">Text</div>
                                <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } />
                                <div className="name_input">Título</div>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } />
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}