import { useState, useEffect } from "react";

import './About.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgImg } from "components/SvgFile";

export default function Model_About(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('site'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enTitle, setEnTitle ]           = useState(InitialData('en_contents', 'en_title'));
    const [ enSubtitle, setEnSubtitle ]     = useState(InitialData('en_contents', 'en_subtitle'));
    const [ enText, setEnText ]             = useState(InitialData('en_contents', 'en_text'));
    const [ enFile, setEnFile ]             = useState(InitialData('en_contents', 'en_file'));
    const [ enStatusFile, setEnStatusFile ] = useState(false);

    // es
    const [ esTitle, setEsTitle ]           = useState(InitialData('es_contents', 'es_title'));
    const [ esSubtitle, setEsSubtitle ]     = useState(InitialData('es_contents', 'es_subtitle'));
    const [ esText, setEsText ]             = useState(InitialData('es_contents', 'es_text'));
    const [ esFile, setEsFile ]             = useState(InitialData('es_contents', 'es_file'));
    const [ esStatusFile, setEsStatusFile ] = useState(false);

    // pt_br
    const [ ptBrTitle, setPtBrTitle ]           = useState(InitialData('pt_br_contents', 'pt_br_title'));
    const [ ptBrSubtitle, setPtBrSubtitle ]     = useState(InitialData('pt_br_contents', 'pt_br_subtitle'));
    const [ ptBrText, setPtBrText ]             = useState(InitialData('pt_br_contents', 'pt_br_text'));
    const [ ptBrFile, setPtBrFile ]             = useState(InitialData('pt_br_contents', 'pt_br_file'));
    const [ ptBrStatusFile, setPtBrStatusFile ] = useState(false);

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('site', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnTitle(InitialData('en_contents', 'en_title'));
        setEnSubtitle(InitialData('en_contents', 'en_subtitle'));
        setEnText(InitialData('en_contents', 'en_text'));
        setEnFile(InitialData('en_contents', 'en_file'));
        setEnStatusFile(false);

        // es
        setEsTitle(InitialData('es_contents', 'es_title'));
        setEsSubtitle(InitialData('es_contents', 'es_subtitle'));
        setEsText(InitialData('es_contents', 'es_text'));   
        setEsFile(InitialData('es_contents', 'es_file'));  
        setEsStatusFile(false);  

        // pt_br
        setPtBrTitle(InitialData('pt_br_contents', 'pt_br_title'));
        setPtBrSubtitle(InitialData('pt_br_contents', 'pt_br_subtitle'));
        setPtBrText(InitialData('pt_br_contents', 'pt_br_text'));
        setPtBrFile(InitialData('pt_br_contents', 'pt_br_file'));
        setPtBrStatusFile(false);   
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "ptBrSubtitle":
                    setPtBrSubtitle(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": value, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;
            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": value, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;
            case "ptBrText":
                    setPtBrText(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": value });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break; 
            case "ptBrFile":
                    if(value){
                        setPtBrFile(value);
                        setPtBrStatusFile(true);
                    }else {
                        setPtBrFile('');
                        setPtBrStatusFile(false);
                    }
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": value, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;

            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": value, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;
            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": value, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;
            case "enText":
                    setEnText(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": value });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;
            case "enFile":
                    if(value){
                        setEnFile(value);
                        setEnStatusFile(true);
                    }else {
                        setEnFile('');
                        setEnStatusFile(false);
                    }
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": value, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;

            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": value, "es_file": esFile, "es_title": esTitle, "es_text": esText });
                break;
            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": value, "es_text": esText });
                break;                
            case "esText":
                    setEsText(value);
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": esFile, "es_title": esTitle, "es_text": value });
                break;
            case "esFile":
                    if(value){
                        setEsFile(value);
                        setEsStatusFile(true);
                    }else {
                        setEsFile('');
                        setEsStatusFile(false);
                    }
                    props.RegisterData('pt_br', { "pt_br_subtitle": ptBrSubtitle, "pt_br_file": ptBrFile, "pt_br_title": ptBrTitle, "pt_br_text": ptBrText });
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_file": enFile, "en_title": enTitle, "en_text": enText });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_file": value, "es_title": esTitle, "es_text": esText });
                break;
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Model_About">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrSubtitle', e.target.value) } } value={ ptBrSubtitle } />
                                <span className="name_input">Subtítulo</span>
                            </div>

                            <div className={ ptBrStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                    <label>
                                        { ptBrStatusFile == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('ptBrFile', e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    ptBrFile !='' ?
                                        ptBrStatusFile == true ?
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(ptBrFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + ptBrFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    : null
                                }
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrTitle', e.target.value) } } value={ ptBrTitle } />
                                <span className="name_input">Título</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrText !='' ? ptBrText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrText', newContent) } />
                            </div>
                        </div>
                    </> :
                idioma == 'en' ?
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } required />
                                <span className="name_input">Caption</span>
                            </div>

                            <div className={ enStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                <label>
                                    { enStatusFile == true ? "Added image" : "Add image" }
                                    <input type="file" onChange={ (e)=>{ HandleData('enFile', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                enFile !='' ?
                                    enStatusFile == true ?
                                    <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(enFile)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + enFile) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                : null
                            }
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } />
                                <span className="name_input">Title</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Text</div>
                                <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } />
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } required />
                                <span className="name_input">Subtítulo</span>
                            </div>

                            <div className={ esStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                <label>
                                    { esStatusFile == true ? "Imagen añadida" : "Añadir imagen" }
                                    <input type="file" onChange={ (e)=>{ HandleData('esFile', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                esFile !='' ?
                                    esStatusFile == true ?
                                    <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(esFile)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + esFile) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                : null
                            }
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } />
                                <span className="name_input">Título</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } />
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}