
let DataPage = {
    "dataConfig" : [],
    "accessDash" : [],
    "site"        : []
};

let NotifyDataPage = {
    "dataConfig" : [],
    "accessDash" : [],
    "site"        : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["dataConfig"].forEach(element => {
        element(value['dataConfig']);
    });
    NotifyDataPage["accessDash"].forEach(element => {
        element(value['accessDash']);
    });
    NotifyDataPage["site"].forEach(element => {
        element(value['site']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
