import { useState, useEffect } from "react";

import './Footer.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgImg } from "components/SvgFile";

export default function Model_Footer(props){
    
    const [ dataPage, setDataPage ] = useState(GetDataPage("site"));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    
    // en
    const [ enNameBtn, setEnNameBtn ]       = useState(InitialData('en_contents', 'en_btn_name_1'));
    const [ enLinkBtn, setEnLinkBtn ]       = useState(InitialData('en_contents', 'en_btn_link_1'));
    const [ enFile, setEnFile ]             = useState(InitialData('en_contents', 'en_file'));
    const [ enStatusFile, setEnStatusFile ] = useState(false);

    const [ enNameBtn_2, setEnNameBtn_2 ] = useState(InitialData('en_contents', 'en_btn_name_2'));
    const [ enLinkBtn_2, setEnLinkBtn_2 ] = useState(InitialData('en_contents', 'en_btn_link_2'));
    const [ enTitle, setEnTitle ]         = useState(InitialData('en_contents', 'en_title'));

    // es
    const [ esNameBtn, setEsNameBtn ]       = useState(InitialData('es_contents', 'es_btn_name_1'));
    const [ esLinkBtn, setEsLinkBtn ]       = useState(InitialData('es_contents', 'es_btn_link_1'));
    const [ esFile, setEsFile ]             = useState(InitialData('es_contents', 'es_file'));
    const [ esStatusFile, setEsStatusFile ] = useState(false);

    const [ esNameBtn_2, setEsNameBtn_2 ] = useState(InitialData('es_contents', 'es_btn_name_2'));
    const [ esLinkBtn_2, setEsLinkBtn_2 ] = useState(InitialData('es_contents', 'es_btn_link_2'));
    const [ esTitle, setEsTitle ]         = useState(InitialData('es_contents', 'es_title'));

    // pt_br
    const [ ptBrNameBtn, setPtBrNameBtn ]       = useState(InitialData('pt_br_contents', 'pt_br_btn_name_1'));
    const [ ptBrLinkBtn, setPtBrLinkBtn ]       = useState(InitialData('pt_br_contents', 'pt_br_btn_link_1'));
    const [ ptBrFile, setPtBrFile ]             = useState(InitialData('pt_br_contents', 'pt_br_file'));
    const [ ptBrStatusFile, setPtBrStatusFile ] = useState(false);

    const [ ptBrNameBtn_2, setPtBrNameBtn_2 ] = useState(InitialData('pt_br_contents', 'pt_br_btn_name_2'));
    const [ ptBrLinkBtn_2, setPtBrLinkBtn_2 ] = useState(InitialData('pt_br_contents', 'pt_br_btn_link_2'));
    const [ ptBrTitle, setPtBrTitle ]         = useState(InitialData('pt_br_contents', 'pt_br_title'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('site', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnNameBtn(InitialData('en_contents', 'en_btn_name_1'));
        setEnLinkBtn(InitialData('en_contents', 'en_btn_link_1'));
        setEnFile(InitialData('en_contents', 'en_file'));
        setEnStatusFile(false);

        setEnNameBtn_2(InitialData('en_contents', 'en_btn_name_2'));
        setEnLinkBtn_2(InitialData('en_contents', 'en_btn_link_2'));
        setEnTitle(InitialData('en_contents', 'en_title'));

        // es
        setEsNameBtn(InitialData('es_contents', 'es_btn_name_1'));
        setEsLinkBtn(InitialData('es_contents', 'es_btn_link_2'));
        setEsFile(InitialData('es_contents', 'es_file'));  
        setEsStatusFile(false);  
        
        setEsNameBtn_2(InitialData('es_contents', 'es_btn_name_2'));
        setEsLinkBtn_2(InitialData('es_contents', 'es_btn_link_2'));
        setEsTitle(InitialData('es_contents', 'es_title'));

        // pt_br
        setPtBrNameBtn(InitialData('pt_br_contents', 'pt_br_btn_name_1'));
        setPtBrLinkBtn(InitialData('pt_br_contents', 'pt_br_btn_link_1'));
        setPtBrFile(InitialData('pt_br_contents', 'pt_br_file'));
        setPtBrStatusFile(false);   
        
        setPtBrNameBtn_2(InitialData('pt_br_contents', 'pt_br_btn_name_2'));
        setPtBrLinkBtn_2(InitialData('pt_br_contents', 'pt_br_btn_link_2'));
        setPtBrTitle(InitialData('pt_br_contents', 'pt_br_title'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "ptBrNameBtn":
                    setPtBrNameBtn(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": value, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 
            case "ptBrLinkBtn":
                    setPtBrLinkBtn(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": value, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;
            case "ptBrFile":
                    if(value){
                        setPtBrFile(value);
                        setPtBrStatusFile(true);
                    }else {
                        setPtBrFile('');
                        setPtBrStatusFile(false);
                    }
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": value, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;
            case "ptBrNameBtn_2":
                    setPtBrNameBtn_2(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": value, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 
            case "ptBrLinkBtn_2":
                    setPtBrLinkBtn_2(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": value, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 
            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": value });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 

            case "enNameBtn":
                    setEnNameBtn(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": value, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;
            case "enLinkBtn":
                    setEnLinkBtn(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": value, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;
            case "enFile":
                    if(value){
                        setEnFile(value);
                        setEnStatusFile(true);
                    }else {
                        setEnFile('');
                        setEnStatusFile(false);
                    }
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": value, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 
            case "enNameBtn_2":
                    setEnNameBtn_2(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": value, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 
            case "enLinkBtn_2":
                    setEnLinkBtn_2(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": value, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 
            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": value });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break; 

            case "esNameBtn":
                    setEsNameBtn(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": value, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;
            case "esLinkBtn":
                    setEsLinkBtn(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": value, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;                
            case "esFile":
                    if(value){
                        setEsFile(value);
                        setEsStatusFile(true);
                    }else {
                        setEsFile('');
                        setEsStatusFile(false);
                    }
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": value, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;
            case "esNameBtn_2":
                    setEsNameBtn_2(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": value, "es_link_btn_2": esLinkBtn_2, "es_title": esTitle });
                break;
            case "esLinkBtn_2":
                    setEsLinkBtn_2(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": value, "es_title": esTitle });
                break;
            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('pt_br', { "pt_br_name_btn": ptBrNameBtn, "pt_br_link_btn": ptBrLinkBtn, "pt_br_file": ptBrFile, "pt_br_name_btn_2": ptBrNameBtn_2, "pt_br_link_btn_2": ptBrLinkBtn_2, "pt_br_title": ptBrTitle });
                    props.RegisterData('en', { "en_name_btn": enNameBtn, "en_link_btn": enLinkBtn, "en_file": enFile, "en_name_btn_2": enNameBtn_2, "en_link_btn_2": enLinkBtn_2, "en_title": enTitle });
                    props.RegisterData('es', { "es_name_btn": esNameBtn, "es_link_btn": esLinkBtn, "es_file": esFile, "es_name_btn_2": esNameBtn_2, "es_link_btn_2": esLinkBtn_2, "es_title": value });
                break;
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Model_Footer">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="show_data_text" style={ { marginTop: '20px' } }>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('ptBrNameBtn', e.target.value) } } value={ ptBrNameBtn } required />
                                    <span className="name_input">Nome do botão</span>
                                </div>
                                <div className={ ptBrStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                    <label>
                                        { ptBrStatusFile == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('ptBrFile', e.target.files[0]); } } accept="image/*" required />
                                    </label>
                                </div>
                                {
                                    ptBrFile !='' ?
                                        ptBrStatusFile == true ?
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(ptBrFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + ptBrFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    : null
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('ptBrLinkBtn', e.target.value) } } value={ ptBrLinkBtn } required />
                                    <span className="name_input">Link do botão</span>
                                </div>
                            </div>
                        </div>

                        <div className="show_data_text" style={ { marginTop: '20px' } }>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('ptBrTitle', e.target.value) } } value={ ptBrTitle } required />
                                    <span className="name_input">Título</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('ptBrNameBtn_2', e.target.value) } } value={ ptBrNameBtn_2 } required />
                                    <span className="name_input">Nome do botão</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('ptBrLinkBtn_2', e.target.value) } } value={ ptBrLinkBtn_2 } required />
                                    <span className="name_input">Link do botão</span>
                                </div>
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ?
                    <>
                        <div className="show_data_text" style={ { marginTop: '20px' } }>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('enNameBtn', e.target.value) } } value={ enNameBtn } required />
                                    <span className="name_input">Button name</span>
                                </div>
                                <div className={ enStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                    <label>
                                        { enStatusFile == true ? "Image added" : "Add image" }
                                        <input type="file" onChange={ (e)=>{ HandleData('enFile', e.target.files[0]); } } accept="image/*" required />
                                    </label>
                                </div>
                                {
                                    enFile !='' ?
                                        enStatusFile == true ?
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(enFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + enFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    : null
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('enLinkBtn', e.target.value) } } value={ enLinkBtn } required />
                                    <span className="name_input">Button link</span>
                                </div>
                            </div>
                        </div>

                        <div className="show_data_text" style={ { marginTop: '20px' } }>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } required />
                                    <span className="name_input">Title</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('enNameBtn_2', e.target.value) } } value={ enNameBtn_2 } required />
                                    <span className="name_input">Button name</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('enLinkBtn_2', e.target.value) } } value={ enLinkBtn_2 } required />
                                    <span className="name_input">Button link</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>                        
                        <div className="show_data_text" style={ { marginTop: '20px' } }>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('esNameBtn', e.target.value) } } value={ esNameBtn } required />
                                    <span className="name_input">Nombre del botón</span>
                                </div>
                                <div className={ esStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                    <label>
                                        { esStatusFile == true ? "Imagen añadida" : "Añadir imagen" }
                                        <input type="file" onChange={ (e)=>{ HandleData('esFile', e.target.files[0]); } } accept="image/*" required />
                                    </label>
                                </div>
                                {
                                    esFile !='' ?
                                        esStatusFile == true ?
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(enFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + enFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    : null
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('esLinkBtn', e.target.value) } } value={ esLinkBtn } required />
                                    <span className="name_input">Botón de enlace</span>
                                </div>
                            </div>
                        </div>

                        <div className="show_data_text" style={ { marginTop: '20px' } }>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } required />
                                    <span className="name_input">Título</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ HandleData('esNameBtn_2', e.target.value) } } value={ esNameBtn_2 } required />
                                    <span className="name_input">Nombre del botón</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('esLinkBtn_2', e.target.value) } } value={ esLinkBtn_2 } required />
                                    <span className="name_input">Botón de enlace</span>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}