import { useState, useEffect } from "react";

import './ConfigDash.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { SvgSave, SvgSetaDown, SvgSetaRight, SvgSetaUp } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { Reg_AltPosition, Reg_EditConfig } from "services/RegisterData";

export default function Page_ConfigDash(){
    
    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('dataConfig'));

    const [ idiomaEn, setIdiomaEn ]     = useState(InitialDataIdioma('en'));
    const [ idiomaEs, setIdiomaEs ]     = useState(InitialDataIdioma('es'));
    const [ idiomaPtBr, setIdiomaPtBr ] = useState(InitialDataIdioma('pt_br'));

    const [ listColor, setListColor ] = useState(InitialData());

    useEffect(()=>{
        RegisterDataPage('dataConfig', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('dataConfig'));
        
        setIdiomaEn(InitialDataIdioma('en'));
        setIdiomaEs(InitialDataIdioma('es'));
        setIdiomaPtBr(InitialDataIdioma('pt_br'));

        setListColor(InitialData());
    }, [dataPage]);

    function InitialDataIdioma(type){
        const dataType = dataPage.find(item => item.type == 'idioma');
        return dataType[type];
    }

    function InitialData(){
        const newData = dataPage.filter(item => item.type == 'color');
        return newData;
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_EditConfig(idiomaEn, idiomaEs, idiomaPtBr, listColor, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function HandleInput(type, index, value){
        const newData = [...listColor];
        newData[index][type] = value;
        setListColor(newData);
    }

    return(
        <div className="Page_ConfigDash">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Configurações
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">Salvar</div>
                        </button>
                    </div>
                </div>
                <div className="">
                    <div className="show_page_data"> 
                        <div className="list_type_data">
                            <div className="type_title">Idiomas ativo no site</div>
                        </div>
                        <div className="list_opt_idioma">
                            <div className={ idiomaPtBr == true ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ setIdiomaPtBr(!idiomaPtBr) } }>Português</div>
                            <div className={ idiomaEn == true ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ setIdiomaEn(!idiomaEn) } }>Inglês</div>
                            <div className={ idiomaEs == true ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ setIdiomaEs(!idiomaEs) } }>Espanhol</div>
                        </div>
                    </div>
                    
                    {
                        listColor.map((key, index)=>{
                            return(
                                <div className="show_page_data" key={ index }> 
                                    <div className="list_type_data">
                                        <div className="type_title">Cor padrão no site - { key.origin_ }</div>
                                    </div>
                                    <table cellPadding="0" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Português</th>
                                                <th>Inglês</th>
                                                <th>Espanhol</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="list_input_data">
                                                        <div className="div_input space_div">
                                                            <input type="text" className="input_table" onChange={ (e)=>{ HandleInput('pt_br', index, e.target.value); } } value={ key.pt_br } />
                                                        </div>
                                                        <div className="div_input">
                                                            <div className="show_color" style={ { backgroundColor: key.pt_br } } />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="list_input_data">
                                                        <div className="div_input space_div">
                                                            <input type="text" className="input_table" onChange={ (e)=>{ HandleInput('en', index, e.target.value); } } value={ key.en } />
                                                        </div>
                                                        <div className="div_input">
                                                            <div className="show_color" style={ { backgroundColor: key.en } } />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="list_input_data">
                                                        <div className="div_input space_div">
                                                            <input type="text" className="input_table" onChange={ (e)=>{ HandleInput('es', index, e.target.value); } } value={ key.es } />
                                                        </div>
                                                        <div className="div_input">
                                                            <div className="show_color" style={ { backgroundColor: key.es } } />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    );
}