import { useState, useEffect } from "react";

import './Home.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg, SvgVimeo, SvgYoutube } from "components/SvgFile";

export default function Model_Home(props){
    
    const [ dataPage, setDataPage ] = useState(GetDataPage("site"));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enContents, setEnContents] = useState(InitialData('en_contents', 'en_contents'));

    // es
    const [ esContents, setEsContents] = useState(InitialData('es_contents', 'es_contents'));

    // pt_br
    const [ ptBrContents, setPtBrContents] = useState(InitialData('pt_br_contents', 'pt_br_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('site', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnContents(InitialData('en_contents', 'en_contents'));

        // es
        setEsContents(InitialData('es_contents', 'es_contents'));  
        
        // pt_br   
        setPtBrContents(InitialData('pt_br_contents', 'pt_br_contents'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'pt_br_contents' || type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        return "";
    }
    
    function NewContent(){
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "typeFile": "Image", "video": "", "file": "", "statusFile": false, "title": "", "text": "", "name_btn": "", "link_btn": "" }]);  
        }

        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "typeFile": "Image", "video": "", "file": "", "statusFile": false, "title": "", "text": "", "name_btn": "", "link_btn": "" }]);
        }
        
        if(idioma == 'pt_br'){
            setPtBrContents([...ptBrContents, { "id": 0, "typeFile": "Image", "video": "", "file": "", "statusFile": false, "title": "", "text": "", "name_btn": "", "link_btn": "" }]);
        }
    }

    function DeleteData(id, index, value){
        if(id == 0){
            if(idioma == 'pt_br'){
                const newData = [...ptBrContents];
                newData.splice(index, 1);
                setPtBrContents(newData);        
            }
            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": 'site', "type": "delete_contents", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleDataContents(type, index, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            if(type == 'file'){
                if(value){
                    newData[index]['file'] = value;
                    newData[index]['statusFile'] = true;
                }else {
                    newData[index]['file'] = "";
                    newData[index]['statusFile'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            props.RegisterData('pt_br', { "pt_br_contents": newData });
            props.RegisterData('en', { "en_contents": enContents });
            props.RegisterData('es', { "es_contents": esContents });
            setPtBrContents(newData);
        }
        if(idioma == 'en'){
            const newData = [...enContents];
            if(type == 'file'){
                if(value){
                    newData[index]['file'] = value;
                    newData[index]['statusFile'] = true;
                }else {
                    newData[index]['file'] = "";
                    newData[index]['statusFile'] = false;
                }
            }else {
                newData[index][type] = value;
            }            
            props.RegisterData('pt_br', { "pt_br_contents": ptBrContents });
            props.RegisterData('en', { "en_contents": newData });
            props.RegisterData('es', { "es_contents": esContents });
            setEnContents(newData);
        }
        if(idioma == 'es'){
            const newData = [...esContents];
            if(type == 'file'){
                if(value){
                    newData[index]['file'] = value;
                    newData[index]['statusFile'] = true;
                }else {
                    newData[index]['file'] = "";
                    newData[index]['statusFile'] = false;
                }
            }else {
                newData[index][type] = value;
            }            
            props.RegisterData('pt_br', { "pt_br_contents": ptBrContents });
            props.RegisterData('en', { "en_contents": enContents });
            props.RegisterData('es', { "es_contents": newData });
            setEsContents(newData);
        }
    }

    return(
        <div className="Model_Home">
            <div className="list_input_data">
                <div className="div_input space_div show_data_text">
                    <div className="div_subtitle_add">
                        <div className="">Slideshow</div>
                        <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                            <span className="icons_menu">+</span> item
                        </div>                                   
                    </div>
                    {
                        idioma == 'pt_br' ? 
                            ptBrContents.map((key, index)=>{
                                return(      
                                    <div className="list_contents" key={ index } style={ { flexDirection: 'column' } }>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <select className="" onChange={ (e)=>{ HandleDataContents('typeFile', index, e.target.value); } } value={ key.typeFile }>
                                                    <option value="Image">Imagem</option>
                                                    <option value="Vimeo">Vídeo - Vimeo</option>
                                                    <option value="Youtube">Vídeo - Youtube</option>
                                                </select>
                                                <span className="name_input">Tipo</span>
                                            </div>
                                            {
                                                key.typeFile == 'Image' ?
                                                <>
                                                    <div className="div_input space_top">
                                                        <div className={ key.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusFile == true ? "Imagem do produto adicionada" : "Adicionar imagem do fundo" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('file', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.statusFile == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                </> :
                                                <>
                                                    <div className="div_input">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('video', index, e.target.value) } } value={ key.video } />
                                                        <span className="name_input">Código do video</span>
                                                    </div>
                                                    {
                                                        key.video == '' ? null :
                                                        <div className="div_input space_top" onClick={ ()=>{ OpenFile(key.typeFile, key.video) } }>
                                                            {
                                                                key.typeFile == 'Vimeo' ? 
                                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                                key.typeFile == 'Youtube' ? 
                                                                <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                            <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.title) } } style={ { display: 'flex' } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>

                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <input type="text" className="name_btn" onChange={ (e)=>{ HandleDataContents('name_btn', index, e.target.value) } } value={ key.name_btn } />
                                                <span className="name_input">Nome botão</span>
                                            </div>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataContents('link_btn', index, e.target.value) } } value={ key.link_btn.replaceAll(' ', '') } />
                                                <span className="name_input">Link botão</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input space_div show_data_text">
                                                <div className="show_title">Texto</div>
                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) 
                        :
                        idioma == 'en' ? 
                            enContents.map((key, index)=>{
                                return(      
                                    <div className="list_contents" key={ index } style={ { flexDirection: 'column' } }>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <select className="" onChange={ (e)=>{ HandleDataContents('typeFile', index, e.target.value); } } value={ key.typeFile }>
                                                    <option value="Image">Image</option>
                                                    <option value="Vimeo">Video - Vimeo</option>
                                                    <option value="Youtube">Video - Youtube</option>
                                                </select>
                                                <span className="name_input">Tipo</span>
                                            </div>
                                            {
                                                key.typeFile == 'Image' ?
                                                <>
                                                    <div className="div_input space_top">
                                                        <div className={ key.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusFile == true ? "Product image added" : "Add product image" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('file', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.statusFile == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                </> :
                                                <>
                                                    <div className="div_input">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('video', index, e.target.value) } } value={ key.video } />
                                                        <span className="name_input">Video code</span>
                                                    </div>
                                                    {
                                                        key.video == '' ? null :
                                                        <div className="div_input space_top" onClick={ ()=>{ OpenFile(key.typeFile, key.video) } }>
                                                            {
                                                                key.typeFile == 'Vimeo' ? 
                                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                                key.typeFile == 'Youtube' ? 
                                                                <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                            <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.title) } } style={ { display: 'flex' } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                <span className="name_input">Title</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <input type="text" className="name_btn" onChange={ (e)=>{ HandleDataContents('name_btn', index, e.target.value) } } value={ key.name_btn } />
                                                <span className="name_input">Button name</span>
                                            </div>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataContents('link_btn', index, e.target.value) } } value={ key.link_btn.replaceAll(' ', '') } />
                                                <span className="name_input">Link button</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input space_div show_data_text">
                                                <div className="show_title">Text</div>
                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : 
                            esContents.map((key, index)=>{
                                return(      
                                    <div className="list_contents" key={ index } style={ { flexDirection: 'column' } }>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <select className="" onChange={ (e)=>{ HandleDataContents('typeFile', index, e.target.value); } } value={ key.typeFile }>
                                                    <option value="Image">Imagem</option>
                                                    <option value="Vimeo">Vídeo - Vimeo</option>
                                                    <option value="Youtube">Vídeo - Youtube</option>
                                                </select>
                                                <span className="name_input">Tipo</span>
                                            </div>
                                            {
                                                key.typeFile == 'Image' ?
                                                <>
                                                    <div className="div_input space_top">
                                                        <div className={ key.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusFile == true ? "Imagen del producto añadida" : "Agregar imagen del producto" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('file', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.statusFile == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                </> :
                                                <>
                                                    <div className="div_input">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('video', index, e.target.value) } } value={ key.video } />
                                                        <span className="name_input">Código do video</span>
                                                    </div>
                                                    {
                                                        key.video == '' ? null :
                                                        <div className="div_input space_top" onClick={ ()=>{ OpenFile(key.typeFile, key.video) } }>
                                                            {
                                                                key.typeFile == 'Vimeo' ? 
                                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                                key.typeFile == 'Youtube' ? 
                                                                <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                            <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.title) } } style={ { display: 'flex' } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.name } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <input type="text" className="name_btn" onChange={ (e)=>{ HandleDataContents('name_btn', index, e.target.value) } } value={ key.name_btn } />
                                                <span className="name_input">Nombre del botón</span>
                                            </div>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataContents('link_btn', index, e.target.value) } } value={ key.link_btn.replaceAll(' ', '') } />
                                                <span className="name_input">Botón de enlace</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input space_div show_data_text">
                                                <div className="show_title">Texto</div>
                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) 
                    }
                </div>
            </div>
        </div>
    )
}