import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { Reg_RegisterData } from "services/RegisterData";
import Model_Home from "components/Model/Home";
import Model_Gallery from "components/Model/Gallery";
import Model_Products from "components/Model/Products";
import Model_Testimonials from "components/Model/Testimonials";
import Model_About from "components/Model/About";
import Model_Contact from "components/Model/Contact";
import Model_Footer from "components/Model/Footer";

export default function Page_Site_Details(){

    const [ loading, setLoading ]   = useState(false);

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ idioma, setIdioma ]         = useState(GetListPag('idioma'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("site"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ title, setTitle ]       = useState(InitialData('title_page'));
    const [ color, setColor ]       = useState(InitialData('color'));
    const [ typePage, setTypePage ] = useState(InitialData('type_page'));

    // en
    const [ enContent, setEnContent ] = useState(InitialData('en_contents'));

    // es
    const [ esContent, setEsContent ] = useState(InitialData('es_contents'));

    // pt_br
    const [ ptBrContent, setPtBrContent ] = useState(InitialData('pt_br_contents'));

    const [ namePageIdioma, setNamePageIdioma ] = useState([
        {
            "en": "Details",
            "es": "Detalles",
            "pt_br": "Detalhes"
        },
        {
            "en": "To save",
            "es": "Ahorrar",
            "pt_br": "Salvar"
        },
        {
            "en": "Page data",
            "es": "Datos de la página",
            "pt_br": "Dados da página"
        },
        {
            "en": "Title",
            "es": "Título",
            "pt_br": "Título"
        },
        {
            "en": "Background color",
            "es": "Color de fondo",
            "pt_br": "Cor de fundo"
        },
        {
            "en": "Page type",
            "es": "Tipo de página",
            "pt_br": "Tipo de página"
        },
        {
            "en": "Example of how the page will look",
            "es": "Ejemplo de cómo se verá la página",
            "pt_br": "Exemplo de como ficará a página"
        }
    ]);

    const [ pageList, setPageList ] = useState([
        {
            "key": "home",
            "name": {
                "en": "Home",
                "es": "Home",
                "pt_br": "Home"
            }
        },
        {
            "key": "gallery",
            "name": {
                "en": "Gallery",
                "es": "Galería",
                "pt_br": "Galeria"
            }
        },
        {
            "key": "products",
            "name": {
                "en": "Products",
                "es": "Productos",
                "pt_br": "Produtos"
            }
        },
        {
            "key": "testimonials",
            "name": {
                "en": "Testimonials",
                "es": "Testimonios",
                "pt_br": "Testemunhos"
            }
        },
        {
            "key": "about",
            "name": {
                "en": "About",
                "es": "Acerca",
                "pt_br": "Quem somos"
            }
        },
        {
            "key": "contact",
            "name": {
                "en": "Contact",
                "es": "Contacto",
                "pt_br": "Contato"
            }
        },
        {
            "key": "footer",
            "name": {
                "en": "Footer",
                "es": "Pie de página",
                "pt_br": "Rodapé"
            }
        }
    ]);
    
    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);
        RegisterDataPage('site', setDataPage);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("site"));
        setIdioma(GetListPag('idioma'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title_page'));
        setColor(InitialData('color'));
        setTypePage(InitialData('type_page'));

        // en
        setEnContent(InitialData('en_contents'));
        // es
        setEsContent(InitialData('es_contents'));
        // pt_br
        setPtBrContent(InitialData('pt_br_contents'));
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'en_contents' || type == 'es_contents' || type == 'pt_br_contents'){
            return [];
        }
        return '';
    }

    function ClickIdioma(value){
        setIdioma(value);
        SetListPag('idioma', value);
    }

    function RegisterData(idioma_data, value){
        // en
        if(idioma_data == 'en'){
            setEnContent(value);
        }
        // es
        if(idioma_data == 'es'){
            setEsContent(value);
        }
        // pt_br
        if(idioma_data == 'pt_br'){
            setPtBrContent(value);
        }
    }

    function CurrentPage(){
        switch (typePage) {
            case "home":
                return <Model_Home RegisterData={ RegisterData } />;

            case "gallery":
                return <Model_Gallery RegisterData={ RegisterData } />;

            case "products":
                return <Model_Products RegisterData={ RegisterData } />;

            case "testimonials":
                return <Model_Testimonials RegisterData={ RegisterData } />;

            case "about":
                return <Model_About RegisterData={ RegisterData } />;

            case "contact":
                return <Model_Contact RegisterData={ RegisterData } />;

            case "footer":
                return <Model_Footer RegisterData={ RegisterData } />;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        console.log(ptBrContent);
        Reg_RegisterData(userId, idPage, title, color, typePage, ptBrContent, enContent, esContent, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'site');
        SetListPag('currentPageId', 'remuve');
    }
    
    return(
        <div className="Page_Site_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Site
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            { namePageIdioma[0][idioma] }
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <div className={ idioma == 'pt_br' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('pt_br'); } }>pt-br</div>
                        <div className={ idioma == 'en' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('en') } }>en</div>
                        <div className={ idioma == 'es' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('es') } }>es</div>
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                { namePageIdioma[1][idioma] }
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="title_page" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="60" required />
                            <span className="name_input">{ namePageIdioma[3][idioma] }</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setColor(e.target.value) } } value={ color } required />
                            <span className="name_input">{ namePageIdioma[4][idioma] }</span>
                        </div>
                        <div className="div_input">
                            <div className="show_color" style={ { backgroundColor: color } } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data"> 
                    <div className="list_type_data">
                        <div className="type_title">
                            { namePageIdioma[2][idioma] }
                            {
                                idPage == 0 ? null : " - " + typePage
                            }
                        </div>
                    </div>
                    <div className="list_input_data">
                        {
                            idPage == 0 ?
                            <div className="div_input">
                                <select className="select_type" onChange={ (e)=>{ setTypePage(e.target.value) } } value={ typePage } required>
                                    <option value="">#</option>
                                    {
                                        pageList.map((key, index)=>{
                                            return(
                                                <option value={ key.key } key={ index }>{ key.name[idioma] }</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="name_input">{ namePageIdioma[5][idioma] }</span>
                            </div> : null
                        }
                    </div>
                    { CurrentPage() }
                </div>

                {
                    typePage == '' ? null : 
                    <div className="show_page_data"> 
                        <div className="list_type_data">
                            <div className="type_title">{ namePageIdioma[6][idioma] }</div>
                        </div>
                        <div className="list_input_data">
                        </div>
                    </div> 
                }
            </form>
        </div>
    )
}