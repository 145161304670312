import { useState, useEffect } from "react";

import './Testimonials.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg } from "components/SvgFile";

export default function Model_Testimonials(props){
    
    const [ dataPage, setDataPage ] = useState(GetDataPage("site"));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enTitle, setEnTitle ]      = useState(InitialData('en_contents', 'en_title'));
    const [ enText, setEnText ]        = useState(InitialData('en_contents', 'en_text'));
    const [ enContents, setEnContents] = useState(InitialData('en_contents', 'en_contents'));

    // es
    const [ esTitle, setEsTitle ]      = useState(InitialData('es_contents', 'es_title'));
    const [ esText, setEsText ]        = useState(InitialData('es_contents', 'es_text'));
    const [ esContents, setEsContents] = useState(InitialData('es_contents', 'es_contents'));

    // pt_br
    const [ ptBrTitle, setPtBrTitle ]      = useState(InitialData('pt_br_contents', 'pt_br_title'));
    const [ ptBrText, setPtBrText ]        = useState(InitialData('pt_br_contents', 'pt_br_text'));
    const [ ptBrContents, setPtBrContents] = useState(InitialData('pt_br_contents', 'pt_br_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('site', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnTitle(InitialData('en_contents', 'en_title'));
        setEnText(InitialData('en_contents', 'en_text'));
        setEnContents(InitialData('en_contents', 'en_contents'));

        // es
        setEsTitle(InitialData('es_contents', 'es_title'));
        setEsText(InitialData('es_contents', 'es_text')); 
        setEsContents(InitialData('es_contents', 'es_contents'));  
        
        // pt_br
        setPtBrTitle(InitialData('pt_br_contents', 'pt_br_title'));
        setPtBrText(InitialData('pt_br_contents', 'pt_br_text'));      
        setPtBrContents(InitialData('pt_br_contents', 'pt_br_contents'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'pt_br_contents' || type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "ptBrTitle":
                    setPtBrTitle(value);
                    props.RegisterData('pt_br', { "pt_br_title": value, "pt_br_text": ptBrText, "pt_br_contents": ptBrContents });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break;
            case "ptBrText":
                    setPtBrText(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": value, "pt_br_contents": ptBrContents });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break;

            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "pt_br_contents": ptBrContents });
                    props.RegisterData('en', { "en_title": value, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break;
            case "enText":
                    setEnText(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "pt_br_contents": ptBrContents });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": value, "en_contents": enContents });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break; 

            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "pt_br_contents": ptBrContents });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_title": value, "es_text": esText, "es_contents": esContents });
                break;                
            case "esText":
                    setEsText(value);
                    props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "pt_br_contents": ptBrContents });
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": value, "es_contents": esContents });
                break;
        }
    }

    function NewContent(){
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "file": "", "statusFile": false, "text": "", "title": "", "office": "", "user": "", "statusUser": false }]);  
        }

        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "file": "", "statusFile": false, "text": "", "title": "", "office": "", "user": "", "statusUser": false }]);              
        }
        
        if(idioma == 'pt_br'){
            setPtBrContents([...ptBrContents, { "id": 0, "file": "", "statusFile": false, "text": "", "title": "", "office": "", "user": "", "statusUser": false }]);           
        }
    }

    function DeleteData(id, index, value){
        if(id == 0){
            if(idioma == 'pt_br'){
                const newData = [...ptBrContents];
                newData.splice(index, 1);
                setPtBrContents(newData);        
            }
            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": 'site', "type": "delete_contents", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleDataContents(type, index, value){
        if(idioma == 'pt_br'){
            const newData = [...ptBrContents];
            if(type == 'file'){
                if(value){
                    newData[index]['file'] = value;
                    newData[index]['statusFile'] = true;
                }else {
                    newData[index]['file'] = "";
                    newData[index]['statusFile'] = false;
                }
            }else if(type == 'user'){
                if(value){
                    newData[index]['user'] = value;
                    newData[index]['statusUser'] = true;
                }else {
                    newData[index]['user'] = "";
                    newData[index]['statusUser'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "pt_br_contents": newData });
            props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_contents": enContents });
            props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_contents": esContents });
            setPtBrContents(newData);
        }
        if(idioma == 'en'){
            const newData = [...enContents];
            if(type == 'file'){
                if(value){
                    newData[index]['file'] = value;
                    newData[index]['statusFile'] = true;
                }else {
                    newData[index]['file'] = "";
                    newData[index]['statusFile'] = false;
                }
            }else if(type == 'user'){
                if(value){
                    newData[index]['user'] = value;
                    newData[index]['statusUser'] = true;
                }else {
                    newData[index]['user'] = "";
                    newData[index]['statusUser'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "pt_br_contents": ptBrContents });
            props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_contents": newData });
            props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_contents": esContents });
            setEnContents(newData);
        }
        if(idioma == 'es'){
            const newData = [...esContents];
            if(type == 'file'){
                if(value){
                    newData[index]['file'] = value;
                    newData[index]['statusFile'] = true;
                }else {
                    newData[index]['file'] = "";
                    newData[index]['statusFile'] = false;
                }
            }else if(type == 'user'){
                if(value){
                    newData[index]['user'] = value;
                    newData[index]['statusUser'] = true;
                }else {
                    newData[index]['user'] = "";
                    newData[index]['statusUser'] = false;
                }
            }else {
                newData[index][type] = value;
            }           
            props.RegisterData('pt_br', { "pt_br_title": ptBrTitle, "pt_br_text": ptBrText, "pt_br_contents": ptBrContents });
            props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_contents": enContents });
            props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_contents": newData });
            setEsContents(newData);
        }
    }

    return(
        <div className="Model_Testimonials">
            {
                idioma == 'pt_br' ? 
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('ptBrTitle', e.target.value) } } value={ ptBrTitle } required />
                                <span className="name_input">Título</span>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ ptBrText !='' ? ptBrText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('ptBrText', newContent) } />
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">
                                        Item
                                    </div>
                                    <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                        <span className="icons_menu">+</span> item
                                    </div>                                  
                                </div>
                                {
                                    ptBrContents.map((key, index)=>{
                                        return(      
                                            <div className="list_contents" key={ index } style={ { flexDirection: 'column' } }>
                                                <div className="list_input_data">
                                                    <div className="div_input space_top">
                                                        <div className={ key.statusUser == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusUser == true ? "Imagem do usuário adicionada" : "Adicionar imagem do usuário" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('user', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file_user  ?
                                                            key.statusUser == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file_user)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file_user) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }

                                                    <div className="div_input space_top">
                                                        <div className={ key.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusFile == true ? "Imagem de exemplo adicionada" : "Adicionar imagem de exemplo" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('file', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.statusFile == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Nome</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('office', index, e.target.value) } } value={ key.office } />
                                                        <span className="name_input">Cargo</span>
                                                    </div>
                                                    <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div show_data_text">
                                                        <div className="show_title">Texto</div>
                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </> :
                    idioma == 'en' ?
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } />
                                <div className="name_input">Title</div>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <div className="show_title">Text</div>
                                <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } />
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">
                                        Item
                                    </div>
                                    <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                        <span className="icons_menu">+</span> item
                                    </div>                                 
                                </div>
                                {
                                    enContents.map((key, index)=>{
                                        return(      
                                            <div className="list_contents" key={ index } style={ { flexDirection: 'column' } }>
                                                <div className="list_input_data">
                                                    <div className="div_input space_top">
                                                        <div className={ key.statusUser == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusUser == true ? "User picture added" : "Add user image" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('user', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file_user  ?
                                                            key.statusUser == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file_user)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file_user) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }

                                                    <div className="div_input space_top">
                                                        <div className={ key.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusFile == true ? "Sample image added" : "Add example image" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('file', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.statusFile == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Name</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('office', index, e.target.value) } } value={ key.office } />
                                                        <span className="name_input">Office</span>
                                                    </div>
                                                    <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div show_data_text">
                                                        <div className="show_title">Text</div>
                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } />
                                <div className="name_input">Título</div>
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } />
                            </div>
                        </div>

                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">
                                        Item
                                    </div>
                                    <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                        <span className="icons_menu">+</span> item
                                    </div>                                    
                                </div>
                                {
                                    esContents.map((key, index)=>{
                                        return(      
                                            <div className="list_contents" key={ index } style={ { flexDirection: 'column' } }>
                                                <div className="list_input_data">
                                                    <div className="div_input space_top">
                                                        <div className={ key.statusUser == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusUser == true ? "Imagen de usuario añadida" : "Agregar imagen de usuario" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('user', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file_user  ?
                                                            key.statusUser == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file_user)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file_user) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }

                                                    <div className="div_input space_top">
                                                        <div className={ key.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.statusFile == true ? "Imagen de muestra añadida" : "Agregar imagen de ejemplo" }
                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('file', index, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.statusFile == true ?
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div> :
                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "site/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                            </div>
                                                        : null
                                                    }
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Nombre</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('office', index, e.target.value) } } value={ key.office } />
                                                        <span className="name_input">Oficina</span>
                                                    </div>
                                                    <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div show_data_text">
                                                        <div className="show_title">Texto</div>
                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}